import axios from 'axios';
import arrayMutators from 'final-form-arrays';
import PropTypes from 'prop-types';
import {Component} from 'react';
import {Form} from 'react-final-form';
import Submit from 'sharedComponents/FinalForm/Submit/Submit';
import LocationFormNullState from './components/LocationFormNullState/LocationFormNullState';
import SearchResultTable from './components/SearchResultTable/SearchResultTable';

export class LocationForm extends Component {
  state = {
    selectedLocations: this.props.searchResults.map((result) =>
      JSON.stringify(result)
    ),
  };

  onSubmit = async (values) => {
    const locations = values.locations.map((location) =>
      parseInt(
        typeof location === 'string'
          ? JSON.parse(location).premiseNumber
          : location.premiseNumber,
        10
      )
    );
    const queryString = {
      query: `mutation {
        createChoicePriceQuote(
          premiseNums: [${locations}]
        ) {
          id
          pubIndex
          premises
          greenPlus
          updatedAt
          quoteData {
            managedPrice
            totalUsages
            choicePriceTypeId
            expirationDate
            acctNums
            selectedPrice
            baseIndex
            baseTpFuelIndex
            baseTpFuelIndex2
            baseTpFuelIndex3
            baseTpIndex
            currentEmail
            fmb1
            fmb1Total
            fmb2
            fmb2Total
            fmb3
            fmb3Total
            fpPriceYr1NoAdders
            fpPriceYr2NoAdders
            gFixed1
            gFixed2
            gFixed3
            paramType
            price
            program
            term
            transportCostTotal
            transportCostUnit
            volume
            mthlyVolume
            accountList {
              mailingAddress
              mailingCityState
              mailingZip
              accountNum
              baseIndex
              baseTpFuelIndex
              baseTpIndex
              billClass
              cogPremium
              curDbYear
              divisionRegion
              firstName
              fmb1
              fmb2
              fmb3
              fpPriceYrNoAdders1
              fpPriceYrNoAdders2
              fuelPercent
              gFixed1
              gFixed2
              gFixed3
              lastName
              premiseNumber
              result
              riskFee
              serviceState
              shellFee
              supplierFee
              transportCostTotal
              transportCostUnit
              vFixed1
              vFixed2
              volume
              status
              complete
              verificationId
              controlId
              errorCode
              formattedAddress
            }
          }
        }
      }`,
    };

    const req = await axios.post('/graphql/', queryString);
    this.setState({selectedLocations: values.locations});
    this.props.setQuote(req.data.data.createChoicePriceQuote);
  };

  render() {
    const {searchResults} = this.props;
    const {selectedLocations} = this.state;

    if (searchResults.length === 0) return <LocationFormNullState />;

    return (
      <Form
        onSubmit={this.onSubmit}
        initialValues={{locations: selectedLocations}}
        mutators={{...arrayMutators}}
      >
        {({handleSubmit, submitting}) => (
          <form onSubmit={handleSubmit}>
            <SearchResultTable searchResults={searchResults} />
            <div>
              <Submit
                submitting={submitting}
              >
                Generate Quote
              </Submit>
            </div>
          </form>
        )}
      </Form>
    );
  }
}

export default LocationForm;

LocationForm.propTypes = {
  quote: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),
  preloadQuoteId: PropTypes.string.isRequired,
  searchResults: PropTypes.arrayOf(PropTypes.shape({})),
  setQuote: PropTypes.func.isRequired,
  submitted: PropTypes.bool,
};

LocationForm.defaultProps = {
  searchResults: [],
  submitted: false,
  quote: {},
};
