import React, { Component } from 'react';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Submit from 'sharedComponents/FinalForm/Submit/Submit';
import { Form } from 'react-final-form';
import styles from './GenerateNewQuote.module.scss';

export class GenerateNewQuote extends Component {
  onSubmit = async (values) => {
    const queryString = {
      query: `mutation {
        createChoicePriceQuote(
          premiseNums: [${values.premises}]
        ) {
          id
          pubIndex
          premises
          greenPlus
          updatedAt
          quoteData {
            managedPrice
            totalUsages
            expirationDate
            acctNums
            selectedPrice
            baseIndex
            baseTpFuelIndex
            baseTpFuelIndex2
            baseTpFuelIndex3
            baseTpIndex
            currentEmail
            fmb1
            fmb1Total
            fmb2
            fmb2Total
            fmb3
            fmb3Total
            fpPriceYr1NoAdders
            fpPriceYr2NoAdders
            gFixed1
            gFixed2
            gFixed3
            paramType
            price
            program
            term
            transportCostTotal
            transportCostUnit
            volume
            mthlyVolume
            accountList {
              mailingAddress
              mailingCityState
              mailingZip
              accountNum
              baseIndex
              baseTpFuelIndex
              baseTpIndex
              billClass
              cogPremium
              curDbYear
              divisionRegion
              firstName
              fmb1
              fmb2
              fmb3
              fpPriceYrNoAdders1
              fpPriceYrNoAdders2
              fuelPercent
              gFixed1
              gFixed2
              gFixed3
              lastName
              premiseNumber
              result
              riskFee
              serviceState
              shellFee
              supplierFee
              transportCostTotal
              transportCostUnit
              vFixed1
              vFixed2
              volume
              status
              complete
              verificationId
              controlId
              errorCode
              formattedAddress
            }
          }
        }
      }`,
    };

    const req = await axios.post('/graphql/', queryString);
    this.props.history.push(window.location.pathname);
    this.props.callBack(req.data.data.createChoicePriceQuote);
  };

  shouldGenerateNewQuote = () =>
    !this.props.complete &&
    this.props.quote.quoteData &&
    Date.parse(this.props.expireDate) >
      Date.parse(this.props.quote.updatedAt.slice(0, 10));

  render() {
    return (
      <>
        {this.shouldGenerateNewQuote() && (
          <Form
            onSubmit={this.onSubmit}
            initialValues={{ premises: this.props.quote.premises }}
          >
            {({ handleSubmit, submitting }) => (
              <form onSubmit={handleSubmit}>
                <h3 className={styles.expired}>
                  Your quote has expired click below to generate a new quote
                </h3>
                <div>
                  <Submit submitting={submitting}>Generate New Quote</Submit>
                </div>
              </form>
            )}
          </Form>
        )}
      </>
    );
  }
}

export default withRouter(GenerateNewQuote);

GenerateNewQuote.propTypes = {
  complete: PropTypes.bool.isRequired,
  expireDate: PropTypes.string.isRequired,
  callBack: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  quote: PropTypes.shape({
    updatedAt: PropTypes.string,
    premises: PropTypes.arrayOf(PropTypes.number).isRequired,
    quoteData: PropTypes.shape({}),
  }),
};

GenerateNewQuote.defaultProps = {
  quote: {},
};
